import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAnalytics } from './hooks/useAnalytics';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import SearchDialog from './components/SearchDialog';
import PageTransition from './components/PageTransition';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';
import ChatbotScript from './components/ChatbotScript';
import { Toaster } from 'react-hot-toast';
// Lazy load components
const Hero = lazy(() => import('./components/Hero'));
const Features = lazy(() => import('./components/Features'));
const Services = lazy(() => import('./components/Services'));
const Testimonials = lazy(() => import('./components/Testimonials'));
const Stats = lazy(() => import('./components/Stats'));
const Partners = lazy(() => import('./components/Partners'));
const CallToAction = lazy(() => import('./components/CallToAction'));

// Lazy load pages
const ServicesPage = lazy(() => import('./pages/ServicesPage'));
const CaseStudiesPage = lazy(() => import('./pages/CaseStudiesPage'));
const CaseStudyPage = lazy(() => import('./pages/CaseStudyPage'));
const PricingPage = lazy(() => import('./pages/PricingPage'));
const AboutPage = lazy(() => import('./pages/AboutPage'));
const BlogPage = lazy(() => import('./pages/BlogPage'));
const BlogPostPage = lazy(() => import('./pages/BlogPostPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const QuotePage = lazy(() => import('./pages/QuotePage'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const CookiesPage = lazy(() => import('./pages/CookiesPage'));
const GDPRPage = lazy(() => import('./pages/GDPRPage'));

function AppContent() {
  useAnalytics();

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col">
      <ErrorBoundary>
        <Navbar />
        <SearchDialog />
        <ScrollToTop />
        <ChatbotScript />
        <main className="flex-grow">
          <ErrorBoundary>
            <Suspense fallback={<LoadingSpinner />}>
              <PageTransition>
                <Routes>
                  <Route path="/" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <Hero />
                      <Features />
                      <Partners />
                      <Services />
                      <Stats />
                      <Testimonials />
                      <CallToAction />
                    </Suspense>
                  } />
                  <Route path="/services" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <ServicesPage />
                    </Suspense>
                  } />
                  <Route path="/case-studies" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <CaseStudiesPage />
                    </Suspense>
                  } />
                  <Route path="/case-studies/:id" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <CaseStudyPage />
                    </Suspense>
                  } />
                  <Route path="/pricing" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <PricingPage />
                    </Suspense>
                  } />
                  <Route path="/about" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <AboutPage />
                    </Suspense>
                  } />
                  <Route path="/blog" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <BlogPage />
                    </Suspense>
                  } />
                  <Route path="/blog/:id" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <BlogPostPage />
                    </Suspense>
                  } />
                  <Route path="/contact" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <ContactPage />
                    </Suspense>
                  } />
                  <Route path="/quote" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <QuotePage />
                    </Suspense>
                  } />
                  <Route path="/privacy" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <PrivacyPage />
                    </Suspense>
                  } />
                  <Route path="/terms" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <TermsPage />
                    </Suspense>
                  } />
                  <Route path="/cookies" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <CookiesPage />
                    </Suspense>
                  } />
                  <Route path="/gdpr" element={
                    <Suspense fallback={<LoadingSpinner />}>
                      <GDPRPage />
                    </Suspense>
                  } />
                </Routes>
              </PageTransition>
            </Suspense>
          </ErrorBoundary>
        </main>
        <Footer />
        <Toaster />
      </ErrorBoundary>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;