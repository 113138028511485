import React, { useState, useEffect } from 'react';
import { Search, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { blogPosts } from '../data/blogPosts';
import { caseStudies } from '../data/caseStudies';

interface SearchResult {
  title: string;
  description: string;
  url: string;
  type: 'blog' | 'case-study' | 'service';
}

export default function SearchDialog() {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<SearchResult[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setIsOpen(true);
      }
      if (e.key === 'Escape') {
        setIsOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (!query) {
      setResults([]);
      return;
    }

    const searchResults: SearchResult[] = [
      ...blogPosts.map(post => ({
        title: post.title,
        description: post.excerpt,
        url: `/blog/${post.id}`,
        type: 'blog' as const
      })),
      ...caseStudies.map(study => ({
        title: study.title,
        description: study.description,
        url: `/case-studies/${study.id}`,
        type: 'case-study' as const
      }))
    ].filter(result => 
      result.title.toLowerCase().includes(query.toLowerCase()) ||
      result.description.toLowerCase().includes(query.toLowerCase())
    );

    setResults(searchResults);
  }, [query]);

  const handleSelect = (result: SearchResult) => {
    navigate(result.url);
    setIsOpen(false);
    setQuery('');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/50 backdrop-blur-sm z-50 flex items-start justify-center pt-[20vh]">
      <div className="w-full max-w-2xl bg-white rounded-xl shadow-2xl overflow-hidden">
        <div className="p-4 border-b border-gray-200">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search everything... (Press '/' to focus)"
              className="w-full pl-10 pr-4 py-2 rounded-lg border-2 border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none"
              autoFocus
            />
          </div>
        </div>

        {results.length > 0 && (
          <div className="max-h-[60vh] overflow-y-auto p-2">
            {results.map((result, index) => (
              <button
                key={index}
                onClick={() => handleSelect(result)}
                className="w-full p-4 text-left rounded-lg hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-center justify-between">
                  <h3 className="font-semibold text-gray-900">{result.title}</h3>
                  <span className="text-sm text-gray-500 capitalize">{result.type}</span>
                </div>
                <p className="text-gray-600 text-sm mt-1">{result.description}</p>
              </button>
            ))}
          </div>
        )}

        {query && results.length === 0 && (
          <div className="p-4 text-center text-gray-500">
            No results found for "{query}"
          </div>
        )}

        <div className="p-4 bg-gray-50 border-t border-gray-200">
          <div className="flex items-center justify-between text-sm text-gray-500">
            <div className="flex items-center space-x-4">
              <span>Press ↵ to select</span>
              <span>↑↓ to navigate</span>
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="flex items-center hover:text-gray-900"
            >
              <X className="h-4 w-4 mr-1" />
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}