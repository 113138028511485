import { getCLS, getFID, getLCP, getFCP, getTTFB } from 'web-vitals';

export const measureWebVitals = () => {
  getCLS(metric => {
    if (window.gtag) {
      window.gtag('event', 'web_vitals', {
        event_category: 'Web Vitals',
        event_label: 'CLS',
        value: Math.round(metric.value * 1000),
        non_interaction: true,
      });
    }
  });

  getFID(metric => {
    if (window.gtag) {
      window.gtag('event', 'web_vitals', {
        event_category: 'Web Vitals',
        event_label: 'FID',
        value: Math.round(metric.value),
        non_interaction: true,
      });
    }
  });

  getLCP(metric => {
    if (window.gtag) {
      window.gtag('event', 'web_vitals', {
        event_category: 'Web Vitals',
        event_label: 'LCP',
        value: Math.round(metric.value),
        non_interaction: true,
      });
    }
  });

  getFCP(metric => {
    if (window.gtag) {
      window.gtag('event', 'web_vitals', {
        event_category: 'Web Vitals',
        event_label: 'FCP',
        value: Math.round(metric.value),
        non_interaction: true,
      });
    }
  });

  getTTFB(metric => {
    if (window.gtag) {
      window.gtag('event', 'web_vitals', {
        event_category: 'Web Vitals',
        event_label: 'TTFB',
        value: Math.round(metric.value),
        non_interaction: true,
      });
    }
  });
};