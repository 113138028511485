import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Workflow,
  Facebook,
  Twitter,
  Linkedin,
  Instagram,
  Mail,
  Phone,
  MapPin
} from 'lucide-react';
import { PhoneNumber } from './constant'

const navigation = {
  solutions: [
    { name: 'E-commerce Automation', href: '/services#ecommerce' },
    { name: 'Lead Generation', href: '/services#leads' },
    { name: 'Email Marketing', href: '/services#email' },
    { name: 'Custom Integrations', href: '/services#integrations' }
  ],
  company: [
    { name: 'About', href: '/about' },
    { name: 'Case Studies', href: '/case-studies' },
    { name: 'Blog', href: '/blog' },
    { name: 'Contact', href: '/contact', scrollTop: true }
  ],
  legal: [
    { name: 'Privacy Policy', href: '/privacy' },
    { name: 'Terms of Service', href: '/terms' },
    { name: 'Cookie Policy', href: '/cookies' },
    { name: 'GDPR Compliance', href: '/gdpr' }
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://facebook.com/workflowautomationsuk',
      icon: Facebook,
      color: 'hover:text-blue-600'
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/workflowautomat',
      icon: Twitter,
      color: 'hover:text-blue-400'
    },
    {
      name: 'LinkedIn',
      href: 'https://linkedin.com/company/workflow-automations-co',
      icon: Linkedin,
      color: 'hover:text-blue-700'
    },
    {
      name: 'Instagram',
      href: 'https://instagram.com/WorkflowAutomationsCo',
      icon: Instagram,
      color: 'hover:text-pink-600'
    }
  ]
};

export default function Footer() {
  const navigate = useNavigate();

  const handleNavigation = (href: string, scrollTop?: boolean) => {
    if (scrollTop) {
      window.scrollTo(0, 0);
    }
    navigate(href);
  };

  return (
    <footer className="relative bg-gradient-to-b from-gray-50 to-white border-t border-gray-100">
      <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZGVmcz48cGF0dGVybiBpZD0iZ3JpZCIgd2lkdGg9IjQwIiBoZWlnaHQ9IjQwIiBwYXR0ZXJuVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48cGF0aCBkPSJNIDQwIDAgTCAwIDAgMCA0MCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSJncmF5IiBzdHJva2Utb3BhY2l0eT0iMC4wNSIvPjwvcGF0dGVybj48L2RlZnM+PHJlY3Qgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmlkKSIvPjwvc3ZnPg==')] opacity-50"></div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Newsletter Section */}
        <div className="py-12 border-b border-gray-200">
          <div className="max-w-xl mx-auto text-center">
            <h3 className="text-2xl font-bold text-gray-900 mb-4">
              Subscribe to Our Newsletter
            </h3>
            <p className="text-gray-600 mb-6">
              Get the latest automation insights and updates delivered to your inbox.
            </p>
            <div className="flex items-center">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-grow px-4 py-3 rounded-l-xl border-2 border-r-0 border-gray-200 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 outline-none transition-all duration-200"
              />
              <button className="px-6 py-3 bg-indigo-600 text-white rounded-r-xl hover:bg-indigo-700 transition-colors">
                Subscribe
              </button>
            </div>
          </div>
        </div>

        {/* Main Footer Content */}
        <div className="py-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8">
          {/* Brand Column */}
          <div className="lg:col-span-2">
            <Link to="/" className="flex items-center group">
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/20 to-purple-600/20 rounded-lg blur-lg transform group-hover:scale-110 transition-transform duration-300"></div>
                <div className="relative bg-white p-2 rounded-lg shadow-sm">
                  <Workflow className="h-8 w-8 text-indigo-600" />
                </div>
              </div>
              <span className="ml-3 text-xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 text-transparent bg-clip-text">
                Workflow Automations
              </span>
            </Link>

            <p className="mt-4 text-gray-600 max-w-md">
              UK's leading workflow automation specialists. Transform your business operations
              with our bespoke automation solutions.
            </p>

            <div className="mt-8 space-y-4">
              <a
                href="mailto:contactus@workflowautomations.co.uk"
                className="flex items-center text-gray-600 hover:text-indigo-600 transition-colors group"
              >
                <div className="relative">
                  <div className="absolute inset-0 bg-indigo-100 rounded-lg blur group-hover:scale-110 transition-transform duration-300"></div>
                  <div className="relative p-2">
                    <Mail className="h-5 w-5 text-indigo-600" />
                  </div>
                </div>
                <span className="ml-3">contactus@workflowautomations.co.uk</span>
              </a>

              <a
                href="tel:+442030000000"
                className="flex items-center text-gray-600 hover:text-indigo-600 transition-colors group"
              >
                <div className="relative">
                  <div className="absolute inset-0 bg-indigo-100 rounded-lg blur group-hover:scale-110 transition-transform duration-300"></div>
                  <div className="relative p-2">
                    <Phone className="h-5 w-5 text-indigo-600" />
                  </div>
                </div>
                <span className="ml-3">{PhoneNumber}</span>
              </a>

              <div className="flex items-center text-gray-600">
                <div className="relative">
                  <div className="absolute inset-0 bg-indigo-100 rounded-lg blur"></div>
                  <div className="relative p-2">
                    <MapPin className="h-5 w-5 text-indigo-600" />
                  </div>
                </div>
                <span className="ml-3">Coldbath Square Farringdon, London, EC1R, United Kingdom</span>
              </div>
            </div>
          </div>

          {/* Navigation Columns */}
          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
              Solutions
            </h3>
            <ul className="space-y-3">
              {navigation.solutions.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-gray-600 hover:text-indigo-600 transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
              Company
            </h3>
            <ul className="space-y-3">
              {navigation.company.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => handleNavigation(item.href, item.scrollTop)}
                    className="text-gray-600 hover:text-indigo-600 transition-colors"
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
              Legal
            </h3>
            <ul className="space-y-3">
              {navigation.legal.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className="text-gray-600 hover:text-indigo-600 transition-colors"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="border-t border-gray-200 py-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex space-x-4">
              {navigation.social.map((item) => {
                const Icon = item.icon;
                return (
                  <a
                    key={item.name}
                    href={item.href}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`text-gray-400 ${item.color} transition-colors p-2 hover:bg-gray-100 rounded-lg`}
                    aria-label={`Visit our ${item.name} page`}
                  >
                    <Icon className="h-5 w-5" />
                  </a>
                );
              })}
            </div>
            <p className="text-gray-500 text-sm text-center md:text-right">
              © {new Date().getFullYear()} Workflow Automations Co. All rights reserved.
              <span className="block md:inline md:ml-2">
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}