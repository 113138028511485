import { CaseStudy } from '../types/caseStudy';

export const caseStudies: CaseStudy[] = [
  // ... existing case studies remain the same ...

  {
    id: 'financial-services-automation',
    title: 'Financial Services Process Automation for UK Investment Firm',
    company: 'Capital Growth Partners',
    industry: 'Financial Services',
    description: 'Implemented comprehensive financial process automation, reducing processing time by 90% and ensuring FCA compliance.',
    challenge: `
      The client faced multiple operational challenges:
      - Manual data entry causing delays and errors
      - Complex compliance reporting requirements
      - Time-consuming client onboarding process
      - Inefficient document management
      - Limited integration between financial systems
    `,
    solution: `
      We delivered a tailored automation solution:
      - Automated data extraction and validation
      - Compliance report generation and filing
      - Digital client onboarding workflow
      - Intelligent document processing system
      - Cross-platform financial data synchronization
    `,
    implementation: `
      Our implementation approach included:
      1. Comprehensive process audit and compliance review
      2. Custom Make.com workflow development
      3. Integration with existing financial systems
      4. Staff training and compliance documentation
      5. Phased deployment with compliance monitoring
    `,
    results: {
      timeReduction: '90%',
      costSavings: '£250,000',
      roi: '520%',
      additionalMetrics: [
        { label: 'Compliance Accuracy', value: '99.9%' },
        { label: 'Processing Time', value: '-85%' },
        { label: 'Document Processing', value: '10x faster' },
        { label: 'Client Onboarding', value: '75% faster' }
      ]
    },
    testimonial: {
      quote: "The automation solution has transformed our operations, ensuring compliance while significantly reducing processing times.",
      author: "James Wilson",
      role: "Head of Operations"
    },
    tools: ['Make.com', 'Xero', 'DocuSign', 'Microsoft Power BI'],
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?auto=format&fit=crop&w=1200&h=630&q=80',
    tags: ['Financial Services', 'Make.com', 'Compliance', 'Process Automation'],
    nextSteps: [
      'Implement AI-powered risk assessment',
      'Expand automated reporting capabilities',
      'Add real-time compliance monitoring'
    ],
    relatedServices: ['Financial Services Automation', 'Compliance Automation', 'Process Optimization']
  },
  {
    id: 'healthcare-workflow-automation',
    title: 'Healthcare Practice Management Automation for UK Clinic Group',
    company: 'MediCare Clinics',
    industry: 'Healthcare',
    description: 'Automated patient management and appointment scheduling, improving efficiency by 70% while maintaining NHS compliance.',
    challenge: `
      Key healthcare operational challenges:
      - Complex appointment scheduling system
      - Manual patient record management
      - NHS compliance requirements
      - Inefficient resource allocation
      - Limited patient communication
    `,
    solution: `
      Implemented healthcare-specific automation:
      - Intelligent appointment scheduling system
      - Digital patient record automation
      - Compliance-focused workflow automation
      - Resource management optimization
      - Automated patient communications
    `,
    implementation: `
      Healthcare-focused implementation:
      1. NHS compliance assessment
      2. Patient journey mapping
      3. Custom healthcare workflow development
      4. Staff and practitioner training
      5. Gradual system rollout across clinics
    `,
    results: {
      timeReduction: '70%',
      costSavings: '£180,000',
      roi: '390%',
      additionalMetrics: [
        { label: 'Patient Satisfaction', value: '+85%' },
        { label: 'Appointment Efficiency', value: '95%' },
        { label: 'Resource Utilization', value: '+60%' },
        { label: 'Compliance Rate', value: '100%' }
      ]
    },
    testimonial: {
      quote: "This automation system has revolutionized how we manage our clinics, improving both staff efficiency and patient care.",
      author: "Dr. Sarah Thompson",
      role: "Clinical Director"
    },
    tools: ['Zapier', 'NHS APIs', 'Practice Management System', 'Microsoft Teams'],
    image: 'https://images.unsplash.com/photo-1516549655169-df83a0774514?auto=format&fit=crop&w=1200&h=630&q=80',
    tags: ['Healthcare', 'NHS', 'Patient Management', 'Compliance'],
    nextSteps: [
      'Implement telemedicine integration',
      'Add predictive resource allocation',
      'Expand patient self-service options'
    ],
    relatedServices: ['Healthcare Automation', 'Compliance Management', 'Process Optimization']
  },
  {
    id: 'manufacturing-automation-system',
    title: 'Manufacturing Process Automation for UK Industrial Manufacturer',
    company: 'Industrial Innovations Ltd',
    industry: 'Manufacturing',
    description: 'Implemented end-to-end manufacturing process automation, reducing production time by 65% and improving quality control.',
    challenge: `
      Manufacturing challenges included:
      - Manual production tracking
      - Quality control inconsistencies
      - Supply chain communication gaps
      - Resource wastage
      - Limited production analytics
    `,
    solution: `
      Comprehensive manufacturing automation:
      - Real-time production monitoring
      - Automated quality control workflows
      - Supply chain integration
      - Resource optimization system
      - Advanced analytics dashboard
    `,
    implementation: `
      Structured implementation process:
      1. Production workflow analysis
      2. Custom automation development
      3. Quality control system integration
      4. Production team training
      5. Phased deployment across facilities
    `,
    results: {
      timeReduction: '65%',
      costSavings: '£320,000',
      roi: '480%',
      additionalMetrics: [
        { label: 'Quality Improvement', value: '+75%' },
        { label: 'Waste Reduction', value: '-60%' },
        { label: 'Production Capacity', value: '+45%' },
        { label: 'Supply Chain Efficiency', value: '85%' }
      ]
    },
    testimonial: {
      quote: "The automation system has transformed our manufacturing processes, significantly improving efficiency and quality control.",
      author: "Robert Johnson",
      role: "Production Manager"
    },
    tools: ['Make.com', 'ERP System', 'Quality Management System', 'Power BI'],
    image: 'https://images.unsplash.com/photo-1581091226825-a6a2a5aee158?auto=format&fit=crop&w=1200&h=630&q=80',
    tags: ['Manufacturing', 'Process Automation', 'Quality Control', 'Analytics'],
    nextSteps: [
      'Implement predictive maintenance',
      'Add AI quality inspection',
      'Expand supply chain automation'
    ],
    relatedServices: ['Manufacturing Automation', 'Process Optimization', 'Analytics & Reporting']
  }
];

// Helper functions remain the same...
export const getRelatedCaseStudies = (currentId: string, limit: number = 2): CaseStudy[] => {
  return caseStudies
    .filter(study => study.id !== currentId)
    .slice(0, limit);
};

export const getCaseStudyById = (id: string): CaseStudy | undefined => {
  return caseStudies.find(study => study.id === id);
};