import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Workflow, Menu, X } from 'lucide-react';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isHomePage = location.pathname === '/';

  const navigation = [
    { name: 'Services', href: '/services' },
    { name: 'Case Studies', href: '/case-studies' },
    { name: 'Pricing', href: '/pricing' },
    { name: 'About', href: '/about' },
    { name: 'Blog', href: '/blog' },
  ];

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${
      isScrolled 
        ? 'bg-white/95 backdrop-blur-lg shadow-lg' 
        : isHomePage
          ? 'bg-transparent'
          : 'bg-white/95 backdrop-blur-lg shadow-lg'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-20">
          {/* Logo */}
          <div className="flex items-center flex-shrink-0">
            <Link 
              to="/" 
              className="flex items-center group"
            >
              <div className="relative">
                <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/20 to-purple-600/20 rounded-lg blur-lg transform group-hover:scale-110 transition-transform duration-300"></div>
                <div className="relative p-2">
                  <Workflow className={`h-8 w-8 ${
                    isScrolled || !isHomePage ? 'text-indigo-600' : 'text-white'
                  }`} />
                </div>
              </div>
              <span className={`ml-3 text-lg xl:text-xl font-bold bg-gradient-to-r ${
                isScrolled || !isHomePage 
                  ? 'from-indigo-600 to-purple-600' 
                  : 'from-white to-white/90'
              } bg-clip-text text-transparent whitespace-nowrap`}>
                Workflow Automations
              </span>
            </Link>
          </div>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center justify-end flex-1 space-x-2 lg:space-x-4 xl:space-x-6">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={`px-3 lg:px-4 py-2 rounded-lg text-sm lg:text-base transition-all duration-200 relative group whitespace-nowrap ${
                  location.pathname === item.href
                    ? isScrolled || !isHomePage 
                      ? 'text-indigo-600 font-semibold' 
                      : 'text-white font-semibold'
                    : isScrolled || !isHomePage 
                      ? 'text-gray-700 hover:text-indigo-600' 
                      : 'text-white/90 hover:text-white'
                }`}
              >
                {item.name}
                <span className={`absolute bottom-0 left-0 w-full h-0.5 transform scale-x-0 transition-transform duration-200 ${
                  location.pathname === item.href
                    ? 'bg-indigo-600 scale-x-100'
                    : 'bg-current group-hover:scale-x-100'
                }`} />
              </Link>
            ))}
            <Link
              to="/quote"
              className={`ml-2 lg:ml-4 px-4 lg:px-6 py-2 rounded-lg text-sm lg:text-base font-medium transition-all duration-300 transform hover:-translate-y-0.5 hover:shadow-lg whitespace-nowrap ${
                isScrolled || !isHomePage
                  ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white hover:from-indigo-700 hover:to-purple-700'
                  : 'bg-white/10 backdrop-blur-sm text-white hover:bg-white/20 border border-white/20'
              }`}
            >
              Get a Quote
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden flex items-center">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`p-2 rounded-lg transition-colors ${
                isScrolled || !isHomePage 
                  ? 'text-gray-900 hover:bg-gray-100' 
                  : 'text-white hover:bg-white/10'
              }`}
              aria-label="Toggle menu"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Navigation */}
      <div 
        className={`${isOpen ? 'block' : 'hidden'} md:hidden bg-white border-t border-gray-100 shadow-lg`}
      >
        <div className="px-4 pt-2 pb-4 space-y-1">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.href}
              onClick={() => setIsOpen(false)}
              className={`block px-4 py-3 rounded-lg transition-all duration-200 ${
                location.pathname === item.href
                  ? 'bg-gradient-to-r from-indigo-50 to-purple-50 text-indigo-600 font-semibold'
                  : 'text-gray-700 hover:bg-gray-50'
              }`}
            >
              {item.name}
            </Link>
          ))}
          <Link
            to="/quote"
            onClick={() => setIsOpen(false)}
            className="block px-4 py-3 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg text-center font-medium hover:from-indigo-700 hover:to-purple-700 transition-all duration-300 transform hover:-translate-y-0.5 hover:shadow-lg"
          >
            Get a Quote
          </Link>
        </div>
      </div>
    </nav>
  );
}