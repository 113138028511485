import React, { useEffect } from 'react';

declare global {
  interface Window {
    embeddedChatbotConfig: {
      chatbotId: string;
      domain: string;
    };
  }
}

export default function ChatbotScript() {
  useEffect(() => {
    // Check if the chatbot script is already loaded
    const existingScript = document.querySelector('script[src="https://www.chatbase.co/embed.min.js"]');
    
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://www.chatbase.co/embed.min.js';
      script.setAttribute('chatbotId', 'AKTXG7fPdw8CQITGwbNPt');
      script.setAttribute('domain', 'www.chatbase.co');
      script.defer = true;
      
      script.onerror = () => {
        console.error('Error loading chatbot script');
      };
      
      document.body.appendChild(script);
      
      return () => {
        document.body.removeChild(script);
      };
    }
  }, []);

  return null;
}